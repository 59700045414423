/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './app/themes/angular-calendar';

@import './app/themes/nav';
@import './app/themes/wizard-theme.css';
//@import 'ng-wizard/themes/ng_wizard';

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left);
}

.clickable {
  cursor: pointer;
}

.button-menu {
  display: flex;
  justify-content: flex-end;

  &__button {
    padding: 8px;
    margin-left: 8px;
  }
}

.truncate {
  line-height: 1.3;
}

.bg-light {
  background: var(--bg-light);
  background-color: var(--bg-light);
}

button {
  &.bg-light {

    &:hover {
      background: var(--bg-dark);
    }
  }
}

.bg-dark {
  background-color: var(--bg-dark);
}

.modal-calculator {
  .modal-wrapper {
    max-width: min(378px, 100vw);
    max-height: min(654px, 100vh);
    border-radius: 6px;
    margin: 4px;
  }
}

.selected-tile  {
  box-shadow: 0 0 0 1.5pt var(--bg-light);
}

// on IOS there is no placeholder for the date and the input fields shrink and are then not clickable
// https://codepen.io/helev/pen/VwZbgem
input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.4em;
  color: #5d5d5d;
}

.background-gray {
  background: var(--background-secondary-green-gradient);
}

ng-wizard,
ng-wizard-step,
.ng-wizard-main,
.ng-wizard-container,
.step-content {
  display: flex !important;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  flex-grow: 1;
}
.steps-headers {
  text-align: center;
}

.nav-steps {
  border: none;
  padding-bottom: 1px;
  background-color: orange;
  display: inline-block;
  overflow: hidden;
}

.nav-steps>li>a {
  margin-right: 0px;
  line-height: 1.428571429;
  width: 180px;
}

/* step arrow style */
.nav-steps>.step a:after {
  position: absolute;
  z-index: 2;
  content: '';
  top: 0px;
  right: -30px;
  border-bottom: 23px solid transparent;
  border-left: 23px solid orange;
  border-top: 20px solid transparent;
  width: 31px;
}


/* disable step arrow style for last item */
.nav-steps>.step:last-child a:after {
  display: none;
}

/* HOVER STYLE */

/* hover state */
.nav-steps>li a:hover {
  background-color: pink;
  color: white;
  border-radius: 0px;
}



/* step arrow color on hover:after */
.nav-steps .step:hover a:after {
  border-left-color: pink;
}

/* ACTIVE STYLE */

/* active state */
.nav-steps>li.active>a, .nav-steps>li.active>a:hover, .nav-steps>li.active>a:focus {
  background-color: yellow;
  color: black;
  border-radius: 0px;
}

/* step arrow color on active:after */
.nav-steps .step.active a:after {
  border-left-color: yellow;
}

input[type="search"]::-webkit-search-cancel-button {

  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 20px;
  width: 20px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 20px;
  color: black;

}

fa-icon  {
  font-size: 26px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 30px;
}

input::-webkit-date-and-time-value{ text-align:left; }

ion-popover {
  &::part(content) {
    min-width: 320px;
  }
}
