@import 'angular-calendar/scss/angular-calendar.scss';

$text-color: #d5d6d7;
$border-color: rgb(255, 255, 255);
$my-cal-weekend-color: rgb(141, 146, 158);
$today-bg: rgb(215, 216, 215);
$bg-active: rgb(179, 179, 179);;
$cal-black: rgb(152, 151, 151);

// Call the calendar mixin with a sass color map of your theme. Every property is optional.
// For a list of all variables and how they are used,
// see https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar/src/variables.scss
@include cal-theme(
    (
      weekend-color: $my-cal-weekend-color,
      bg-active: $bg-active,
      border-color: $border-color,
      today-bg: $today-bg,
      gray: $cal-black
    )
);


.cal-month-view {
  .cal-cell-row {
    .cal-day-cell {
      border: none;
      min-height: 60px;
      max-height: 60px;

      &.cal-today {
        background: none;
      }
    }

    .cal-cell:hover {
      background: none;
    }
    .cal-cell:focus  {
      outline-color: transparent;
    }
  }
}

