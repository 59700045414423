.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  min-height: 45px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .nav {
    font-size: 14px;
    min-height: 42px;
  }
}

.nav-link {
  display: block;
}

.nav-tabs {
  .nav-link {
    &.disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.ng-wizard-theme-arrows>ul.step-anchor>li>a:after,
.ng-wizard-theme-arrows>ul.step-anchor>li>a:before {
  display: inherit;
}
