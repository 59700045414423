
.ng-wizard-theme-arrows {
  border-radius: 5px;
  border: 1px solid #ddd;
}

.ng-wizard-theme-arrows > .ng-wizard-container {
  min-height: 200px;
}

.ng-wizard-theme-arrows .step-content {
  border: 0px solid #D4D4D4;
  background-color: #FFF;
  text-align: left;
}

.ng-wizard-theme-arrows .ng-wizard-toolbar {
  padding: 10px;
  margin-bottom: 0 !important;
}


.ng-wizard-theme-arrows > ul.step-anchor {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 0px;
  background: #f5f5f5;
  border-radius: 0;
  border-top-right-radius: 5px;
  list-style: none;
  overflow: hidden;
}

.ng-wizard-theme-arrows > ul.step-anchor li + li:before {
  padding: 0;
}

.ng-wizard-theme-arrows > ul.step-anchor > li {
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a,
.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
  color: #bbb;
  text-decoration: none;
  padding: 10px 0 10px 45px;
  position: relative;
  display: block;
  border: 0 !important;
  border-radius: 0;
  outline-style: none;
  background: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .ng-wizard-theme-arrows > ul.step-anchor > li > a,
  .ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
    padding: 10px 0 10px 35px;
  }
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.ng-wizard-theme-arrows > ul.step-anchor > li:first-child > a {
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .ng-wizard-theme-arrows > ul.step-anchor > li:first-child > a {
    padding-left: 6px;
  }
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
  color: #bbb;
  text-decoration: none;
  outline-style: none;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover:after {
  border-left-color: #f5f5f5;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a small {
}

.ng-wizard-theme-arrows > ul.step-anchor > li.clickable > a:hover {
  color: #4285F4 !important;
  background: #46b8da !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a {
  border-color: #383a3e !important;
  color: #fff !important;
  background: #383a3e !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a:after {
  border-left: 30px solid #383a3e !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a {
  border-color: #686b6b !important;
  /*  #5cb85c */
  color: #fff !important;
  background: #686b6b !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a:after {
  border-left: 30px solid #686b6b;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.danger > a {
  border-color: #d9534f !important;
  color: #fff !important;
  background: #d9534f !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.danger > a:after {
  border-left: 30px solid #d9534f !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a,
.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a:hover {
  color: #eee !important;
}

/* Loader Custom Style */
.ng-wizard-theme-arrows::before {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383a3e;
}
